@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");

/* Define common breakpoints */
$mobile: 600px;
$tablet: 992px;
$desktop: 1200px;

.servicePage {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1900px;
  margin: 0 auto;
  overflow-x: hidden;
  box-sizing: border-box !important;

  .serviceHero {
    width: 100%;
    display: flex;
    height: 700px;
    box-sizing: border-box !important;
    background-image: url("../../Assets/contactHeroBg.png");
    background-position: center center;
    background-size: cover;

    /* Left Section */
    .serviceProjects {
      // background-color: #2a565e;
      padding: 70px 80px;
      width: 50%;
      box-sizing: border-box !important;

      .Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box !important;

        .logo {
          background-color: #2a565e;
          border-radius: 10px;
          padding: 10px;
        }
      }

      .headingTitle {
        color: #fff;
        margin: 120px 0 0 0;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 45px;
        box-sizing: border-box !important;

        span {
          font-weight: 700;
          font-size: 50px;
        }
      }

      .heroDesc {
        color: #fff;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 18px;
        margin-top: 20px;
        box-sizing: border-box !important;
        line-height: 30px;
      }
    }
    /* Right Section */
    .serviceDemoForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 0 50px;
      padding-top: 30px;
      box-sizing: border-box !important;
      // background-color: #fff;

      .formTitle {
        font-weight: 500;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 2rem;
        color: #2a565e;
      }

      .landingForm {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        box-sizing: border-box !important;
        width: 75%;
        height: auto;

        background-color: rgba($color: #fff, $alpha: 0.7);
        padding: 20px 40px;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
          rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

        .InputFields {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 0.5rem;
          width: 100%;
          box-sizing: border-box !important;

          label {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.361rem;
            font-family: "Helvetica Neue", sans-serif;
          }

          input,
          textarea {
            border: 1px solid #2a565e;
            border-radius: 10px;
            padding: 1rem;
            font-size: 1rem;
            background-color: transparent;
          }
        }

        .contactBtn {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.21rem;
          background-color: #2a565e;
          color: #ffffff;
          width: 100%;
          padding: 1rem;
          border-radius: 10px;
          border: none;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .BannerPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    background-color: #fff;
    padding: 50px;
    width: 100%;
    box-sizing: border-box !important;

    .bannerInnerContainer {
      width: 100%;
      background-image: url("../../Assets/projectsBg.png");
      background-position: center center;
      background-size: cover;
      padding: 50px 80px;
      border-radius: 12px;
      box-sizing: border-box !important;

      .BannerTitle {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 2.487rem;
        color: #fff;
        margin: 0;
        text-align: center;
      }

      .projectContainer {
        width: 100%;
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
        gap: 20px;
        box-sizing: border-box !important;
        background-color: #fff;
        padding: 30px 50px;
        border-radius: 12px;

        .projectItem {
          display: flex;
          align-items: start;
          gap: 10px;
          background-color: #fff;
          border-radius: 5px;
          padding: 20px;
          box-sizing: border-box !important;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

          .projectLogo {
            object-fit: contain;
            width: 32px;
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 5px;
            box-sizing: border-box !important;

            .title {
              font-size: 12px;
              color: #3b3a40;
              padding: 0;
              margin: 0;
              font-family: "Poppins", sans-serif;
            }

            .description {
              font-size: 8px;
              line-height: 12px;
              color: #64626a;
              padding: 0;
              margin: 0;
              font-family: "Inter", sans-serif;
            }
          }
        }
      }
    }
  }
}

/* Responsive Styles */

/* Tablet adjustments */
@media (max-width: $tablet) {
  .servicePage {
    .serviceHero {
      height: auto; /* let the content define height */

      .serviceProjects {
        width: 100%;
        padding: 20px;
        .headingTitle {
          font-size: 36px;
          span {
            font-size: 40px;
          }
        }
      }

      .serviceDemoForm {
        width: 100%;
        padding: 20px;

        .formTitle {
          font-size: 1.8rem;
        }

        .landingForm {
          .InputFields label {
            font-size: 14px;
          }
          .contactBtn {
            font-size: 0.9rem;
          }
        }
      }
    }

    .BannerPageContainer {
      padding: 4rem;

      .bannerInnerContainer {
        padding: 20px;
        .BannerTitle {
          font-size: 2rem;
        }
        .projectContainer {
          grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
          gap: 10px;
          padding: 15px;
          margin-top: 40px;
        }
      }
    }
  }
}

/* Mobile adjustments */
@media (max-width: $mobile) {
  .servicePage {
    .serviceHero {
      flex-direction: column;

      .serviceProjects,
      .serviceDemoForm {
        width: 100%;
      }

      .serviceProjects {
        padding: 20px;
        .headingTitle {
          font-size: 28px;
          span {
            font-size: 32px;
          }
        }
      }

      .serviceDemoForm {
        padding: 20px;

        .formTitle {
          font-size: 1.5rem;
        }

        .landingForm {
          width: 90%;
          .InputFields {
            label {
              font-size: 14px;
            }

            input,
            textarea {
              font-size: 0.9rem;
            }
          }

          .contactBtn {
            font-size: 1rem;
            padding: 0.75rem;
          }
        }
      }
    }

    .BannerPageContainer {
      padding: 20px;

      .bannerInnerContainer {
        padding: 20px;
        .BannerTitle {
          font-size: 1.8rem;
        }
        .projectContainer {
          grid-template-columns: 1fr; /* single column */
          gap: 10px;
          padding: 15px;
          margin-top: 30px;
        }
      }
    }
  }
}
