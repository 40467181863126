@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.TestimonialsContainer {
	height: 55rem;
	background-color: #2a565e;
	padding: 3rem 0;
	color: #ffffff;
	position: relative;
	.subtitle {
		@include fontStyle(1.437rem, 400, 1.43rem);
		text-align: center;
	}

	.titile {
		@include fontStyle(2.1875rem, 700, 2.176rem);
		text-align: center;
	}

	.Testimonialdesc {
		@include fontStyle(1.437rem, 400, 1.43rem);
		text-align: center;
	}

	.slider-container {
		width: 85%;
		margin: auto;
		height: 50%;
		padding-left: 2rem;
		.slick-slider {
			margin-top: 7rem;
			.card {
				width: 77% !important;

				height: 24rem;
				padding: 2rem;
				border-radius: 10px;
				background-color: #ffffff;
				color: #000000;

				.heading {
					@include flex(row, 1rem, start, center);
					width: 100%;
					margin-bottom: 4rem;

					.companyLogoContainer {
						height: 4rem;
						width: 3rem;
						.companyLogo {
							height: 100%;
							width: 100%;
							object-fit: contain;
						}
					}
					.customerDetails {
						@include flex(row, 0.5rem, space-between, start);
						width: 100%;
						div {
							h4 {
								margin: 0;
								@include fontStyle(1.125rem, 700, 1.119rem);
								padding-bottom: 0.5rem;
								width: 100%;
							}

							.designation {
								color: #6c6c6c;
							}
						}
					}
				}

				.desc {
					@include fontStyle(1rem, 400, 1.25rem);
					color: #575757;
					padding: 1rem;
					background: #0000000a;
					border-radius: 10px;
				}
			}
		}
	}

	.prevArrow,
	.nextArrow {
		background-color: transparent;
		border: none;
		cursor: pointer;
		position: absolute;
		border-radius: 5px;
		height: 4rem;
		width: 4rem;
		border-radius: 5px;
		img {
			height: 100%;
			width: 100%;
		}
	}
	.prevArrow {
		bottom: -8rem;
		left: 40%;
		rotate: 180deg;
	}
	.nextArrow {
		bottom: -8rem;
		right: 45%;
	}
}

@media (max-width: 1380px) {
	.TestimonialsContainer {
		.prevArrow {
			left: 43%;
		}
		.nextArrow {
			right: 43%;
		}
	}
}

@media (max-width: 1280px) {
	.TestimonialsContainer {
		.slider-container {
			.slick-slider {
				.card {
					.heading {
						.rating {
							margin-left: 10%;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.TestimonialsContainer {
		height: 80vh;
		.Testimonialdesc {
			padding: 0 5rem;
		}
		.prevArrow {
			left: 40%;
		}
		.nextArrow {
			right: 40%;
		}
	}
}

@media (max-width: 768px) {
	.TestimonialsContainer {
		height: 80vh;
		padding: 3rem 0;
		.subtitle {
			@include fontStyle(1.25rem, 400, 1.187rem);
		}

		.titile {
			@include fontStyle(1.75rem, 700, 1.741rem);
		}

		.Testimonialdesc {
			@include fontStyle(1rem, 400, 0.995rem);
			padding: 0 1rem;
		}
		.slider-container {
			width: 90%;
			height: 60%;
			padding-left: 0;
			.slick-slider {
				margin-top: 2rem;
				.card {
					width: 87% !important;
					padding: 1.5rem;
					height: 27rem;
					.heading {
						margin-bottom: 2rem;
					}
				}
			}
		}
		.prevArrow {
			bottom: -5.5rem;
			left: 30%;
			display: none;
			img {
				height: 2rem;
				width: 2rem;
			}
		}
		.nextArrow {
			bottom: -5.6rem;
			right: 30%;
			display: none;

			img {
				height: 2rem;
				width: 2rem;
			}
		}
		// .slick-dots{
		// 	border: 1px solid red;
		// 	button{
		// 		border: 1px solid red;
		// 	}
		// }
	}
}
