@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.RealstatePageContainer {
	@include flex(row, 0, center, center);
	padding: 5rem;
	.realstate {
		@include flex(column, 6rem, center, center);

		.titleConatiner {
			@include flex(row, 0, space-between, start);
			.realstateTitle {
				@include fontStyle(3.125rem, 700, 4rem);
				width: 60%;
				margin: 0;
			}
			.getStartedBtn {
				@include fontStyle(1.125rem, 700, 1.875rem);
				color: #ffffff;
				background-color: #2a565e;
				border: none;
				text-decoration: none;
				padding: 0.5rem 2rem;
				border-radius: 4.375rem;
				transition: background-color 0.09s ease-out;
				&:hover {
					background-color: #357581;
				}
			}
		}
		.gridConatiner {
			@include flex(row, 3rem, center, center);

			flex-wrap: wrap;
			.gridItem {
				@include flex(column, 0, space-between, center);

				width: 24%;
				.gridImg {
					width: 100%;
					height: 60%;

					img {
						height: 100%;
						width: 100%;
						border-radius: 1.25rem;
					}
				}
				h2 {
					@include fontStyle(1.875rem, 700, 1.875rem);
					width: 100%;
				}
				p {
					@include fontStyle(1.1rem, 500, 1.5rem);
					color: #a6a6a6;
					margin: 0;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.RealstatePageContainer {
		padding: 3rem;
		.realstate {
			.gridConatiner {
				@include flex(row, 2rem, center, center);
				.gridItem {
					width: 30%;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.RealstatePageContainer {
		padding: 3rem 1rem 9rem;
		position: relative;
		.realstate {
			@include flex(column, 3rem, center, center);
			.titleConatiner {
				.realstateTitle {
					@include fontStyle(2rem, 700, 1.99rem);
					width: 100%;
					text-align: center;
				}
				.getStartedBtn {
					position: absolute;
					bottom: 2rem;
					margin: auto;
					padding: 0.8rem 2rem;
					left: 28%;
					right: 28%;
					align-self: center;
				}
			}
			.gridConatiner {
				@include flex(column, 6rem, center, center);
				.gridItem {
					width: 95%;
				}
			}
		}
	}
}
