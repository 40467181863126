@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.BannerPageContainer {
	@include flex(column, 4rem, center, center);
	background-color: #2a565e;
	padding: 6rem;
	.BannerTitle {
		@include fontStyle(2.5rem, 700, 2.487rem);
		color: #ffffff;
		margin: 0;
	}
	.BannerLogosContainer {
		@include flex(row, 2rem, center, center);

		.bannerLogoContainer {
			height: 5rem;
			width: 10rem;
			.bannerLogo {
				height: 100%;
				width: 100%;

				object-fit: contain;
			}
		}
	}
}

@media (max-width: 1024px) {
	.BannerPageContainer {
		padding: 5rem;
		.BannerTitle {
			text-align: center;
		}
		.BannerLogosContainer {
			@include flex(row, 2rem, center, center);
			width: 100%;
			.bannerLogo {
				height: 1.5rem;
				width: 10rem;
			}
		}
	}
}
@media (max-width: 768px) {
	.BannerPageContainer {
		@include flex(column, 3rem, center, center);
		padding: 3rem 1rem;
		overflow-x: hidden;
		.BannerTitle {
			@include fontStyle(1.75rem, 700, 1.741rem);
		}
		.BannerLogosContainer {
			@include flex(row, 1.3rem, center, center);
			flex-wrap: wrap;
			.bannerLogoContainer {
				height: 2rem;
				width: 4rem;
				.bannerLogo {
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}
