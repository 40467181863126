@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.HeroSectionContainer {
	@include flex(row, 0, center, center);
	background-image: url("../../Assets/heroBg.svg");
	background-repeat: no-repeat; /* Prevents the background from repeating */
	background-size: cover;
	height: 78vh;
	position: relative;
	.HeroDetails {
		@include flex(column, 0, center, center);
		position: absolute;
		margin: auto;
		top: 14%;
		.Herotitle {
			@include fontStyle(3rem, 700, 3rem);
			width: 100%;
			text-align: center;
		}
		.heroDesc {
			@include fontStyle(1.5rem, 500, 1.875rem);
			margin-top: 0;
		}
		.HerobtnsContainer {
			@include flex(row, 0, center, center);
			margin-top: 1.5rem;
			.getStarted,
			.learnMoreBtn {
				@include flex(row, 1rem, center, center);
				@include fontStyle(1.125rem, 700, 1.125rem);
				color: #ffffff;
				padding: 1.25rem 6rem;
				border: none;
				text-decoration: none;
				border-radius: 2.5rem;
				cursor: pointer;
				background-color: #2a565e;
				transition: background-color 0.09s ease-out;
				&:hover {
					background-color: #357581;
				}
			}
			// .learnMoreBtn {
			// 	background-color: transparent;
			// 	color: #191a15;
			// 	border: none;
			// 	padding: 1.25rem 3rem;
			// 	.playCircle {
			// 		@include flex(row, 0, center, center);
			// 		width: 31.67px;
			// 		height: 31px;
			// 		border: 1.5px solid #191a15;
			// 		border-radius: 50%;
			// 		.play {
			// 			width: 1.2rem;
			// 			height: 1.2rem;
			// 		}
			// 	}
			// }
		}
	}
}

@media (max-width: 1024px) {
	.HeroSectionContainer {
		height: 60vh;
		.HeroDetails {
			top: 5%;
			.heroDesc {
				width: 70%;
				text-align: center;
			}
		}
	}
}

@media (max-width: 768px) {
	.HeroSectionContainer {
		height: 80vh;
		.HeroDetails {
			.Herotitle {
				@include fontStyle(1.75rem, 700, 1.741rem);
				width: 90%;
			}
			.heroDesc {
				@include fontStyle(1rem, 500, 1.875rem);
				width: 90%;
			}
			.HerobtnsContainer {
				@include flex(column, 0, center, center);

				.getStarted,
				.learnMoreBtn {
					padding: 1.25rem 4rem;
				}
			}
		}
	}
}
