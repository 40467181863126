@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.GetStartedContainer {
	@include flex(row, 0, center, center);
	padding: 8rem;
	.item {
		@include flex(row, 7rem, center, center);
		.details {
			@include flex(column, 2rem, center, start);
			h1 {
				@include fontStyle(3.125rem, 600, 3.781rem);
				margin: 0;
			}
		}
		form {
			@include flex(column, 0, space-evenly, center);
			border: 1px solid #2a565e;
			height: 30.625rem;
			width: 38.25rem;
			border-radius: 1.25rem;
			.InputFields {
				@include flex(column, 0.5rem, center, start);

				width: 90%;
				label {
					@include fontStyle(1.125rem, 500, 1.361rem);
				}
				input,
				textarea {
					border: 1px solid #2a565e;
					border-radius: 1.15rem;
					width: 94%;
					padding: 1rem;
				}
			}
			.contactBtn {
				@include fontStyle(1rem, 600, 1.21rem);
				background-color: #2a565e;
				color: #ffffff;
				width: 90%;
				padding: 1rem;
				border-radius: 10px;
				border: none;
			}
		}
	}
}

@media (max-width: 1024px) {
	.GetStartedContainer {
		padding-top: 5rem;
		.item {
			@include flex(column, 2rem, center, center);
			.details {
				img {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.GetStartedContainer {
		padding: 2rem 1rem 3rem;
		.item {
			.details {
				h1 {
					@include fontStyle(1.75rem, 600, 2.118rem);
				}
			}
			form {
				@include flex(column, 0, space-evenly, start);
				width: 19rem;
				position: relative;
				padding: 0 1rem;
				.InputFields {
					width: 95%;
				}
				.contactBtn {
                 width: 100%;
				}
			}
		}
	}
}
