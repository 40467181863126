@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.InsightsPageContainer {
	@include flex(row, 0, center, center);
	padding: 3rem 0 5rem;
	.InsightItem {
		@include flex(row, 8rem, space-between, start);
		width: 72%;
		.insightDetails {
			@include flex(column, 0, space-between, start);
			h2 {
				@include fontStyle(3.125rem, 700, 3.5rem);
			}
			p {
				@include fontStyle(1rem, 500, 1.875rem);
				margin: 0;
				color: #a6a6a6;
			}
			.ratingContainer {
				@include flex(row, 5rem, center, center);
				margin-top: 2rem;
			}
		}
	}
}

@media (max-width: 1500px) {
	.InsightsPageContainer {
		.InsightItem {
			width: 80%;
		}
	}
}

@media (max-width: 1380px) {
	.InsightsPageContainer {
		.InsightItem {
			width: 85%;
		}
	}
}
@media (max-width: 1280px) {
	.InsightsPageContainer {
		.InsightItem {
			@include flex(row, 4rem, space-between, start);
			width: 90%;
		}
	}
}
@media (max-width: 1024px) {
	.InsightsPageContainer {
		.InsightItem {
			@include flex(column, 4rem, space-between, start);
			width: 90%;
			.mainImg {
				height: 20rem;
				width: 100%;
				object-fit: cover;
				border-radius: 20px;
			}
		}
	}
}

@media (max-width: 768px) {
	.InsightsPageContainer {
		width: 100%;
		.InsightItem {
			@include flex(column, 4rem, space-between, start);
			width: 90%;
			.insightDetails {
				h2 {
					@include fontStyle(2rem, 700, 1.99rem);
					text-align: center;
				}
				p {
					text-align: center;
				}
				.ratingContainer {
					@include flex(row, 3rem, center, center);
					margin-top: 2rem;
					img {
						width: 30%;
					}
				}
			}
			.mainImg {
				display: none;
			}
		}
	}
}
