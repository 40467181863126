@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.NavbarContainer {
	width: 100%;
	box-sizing: border-box;
	@include flex(row, 0, space-between, center);
	height: 5.56rem;
	background-color: #2a565e;
	padding: 0 5rem;
	.logo {
		@include flex(row, 0.5rem, space-between, center);
	}
	button.mobile-menu-icon {
		@include flex(row, 0, center, center);
		@include fontStyle(1.3rem, 500, "");
		background: none;
		border: none;
		color: #ffffff;
		cursor: pointer;
		height: 2rem;
		width: 2.5rem;
	}
	.navItems {
		@include flex(row, 2rem, space-between, center);
		a,
		p {
			@include fontStyle(1.1rem, 700, 0.98rem);
			color: #ffffff;
			text-decoration: none;
			cursor: pointer;
		}
		button {
			@include flex(row, 0, center, center);
			@include fontStyle(1.1rem, 600, 0.98rem);
			padding: 0.8rem 0.8rem;
			border-radius: 0.625rem;
			color: #2a565e;
			border: none;
			background-color: #ffffff;
			cursor: pointer;
			transition: background-color 0.09s ease-out;
			&:hover {
				background-color: #faf7f7;
			}
		}
	}
	.hide {
		display: none;
	}
}

@media (max-width: 1024px) {
	.NavbarContainer {
		padding: 0 2rem;
	}
}

@media (max-width: 768px) {
	.NavbarContainer {
		height: 4.8rem;
		padding: 0 1.5rem;
		.logo {
			img {
				height: 2rem;
			}
		}
	}
}
