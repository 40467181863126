@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.PrivacyPageContainer {
	padding: 3.65rem 5.5rem;
	font-family: "Times New Roman", Times, serif;
	h3 {
		margin-bottom: 0.5rem;
		span {
			@include fontStyle(1rem, 500, "");
			padding-left: 0.4rem;
		}
	}
	p {
		margin-top: 0.3rem;
		.email,
		.phone,
		.address {
			font-weight: 700;
		}
	}
}

@media (max-width: "768px") {
	.PrivacyPageContainer {
		padding: 1rem 2rem;
	}
}
