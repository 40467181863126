@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.AboutPageContainer {
	padding: 3.65rem 5.5rem;
	h1 {
		@include fontStyle(2.5rem, 700, 1.5rem);
	}
	div {
		p {
			@include fontStyle(1.1rem, 500, 1.5rem);
		}
	}
}

@media (max-width: "768px") {
	.AboutPageContainer {
		padding: 1rem 2rem;
	}
}
