@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.ContactPageContainer {
	@include flex(row, 0, center, center);
	padding: 4rem 0;
	// background-color: #f6f4f4;
	background-color: #fbf8f8;
	height: fit-content;
	.item {
		@include flex(row, 7rem, center, start);
		.details {
			@include flex(column, 0rem, start, start);

			.title {
				@include fontStyle(3.125rem, 600, 3.781rem);
				margin: 0rem;
			}

			.ContactItemsContainer {
				@include flex(column, 2rem, center, start);

				.contactItem {
					@include flex(row, 2rem, center, start);
					img {
						height: 3.8rem;
						width: 3.8rem;
					}
					div {
						h3 {
							margin: 0;
							padding: 0;
						}
						p {
							max-width: 27rem;
							margin-top: 0.5rem;
							line-height: 1.3rem;
						}
					}
				}
				.address {
					@include flex(column, 0, center, center);
				}
			}
		}
		form {
			@include flex(column, 0, space-evenly, center);
			height: 30rem;
			width: 38.25rem;
			border-radius: 1rem;
			background-color: #ffffff;
			padding: 2rem 0;
			.InputFields {
				@include flex(column, 0.5rem, center, start);

				width: 88%;
				label {
					@include fontStyle(1.125rem, 500, 1.361rem);
				}
				input,
				textarea {
					border: none;
					border-radius: 0.4rem;
					width: 94%;
					padding: 1rem;
					background-color: #f6f4f4;
				}
			}
			.doubleField {
				@include flex(row, 1.4rem, center, start);
			}
			.contactBtn {
				@include fontStyle(1rem, 600, 1.21rem);
				background-color: #2a565e;
				color: #ffffff;
				width: 90%;
				padding: 1rem;
				border-radius: 10px;
				border: none;
			}
		}
	}
}

@media (max-width: 1024px) {
	.ContactPageContainer {
		padding-top: 5rem;
		.item {
			@include flex(column, 2rem, center, center);
			.details {
				width: 38.25rem;
				.ContactItemsContainer {
					@include flex(row, 2rem, space-between, start);
					flex-wrap: wrap;
					padding-right: 2rem;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.ContactPageContainer {
		padding: 2rem 1rem 3rem;
		.item {
			@include flex(column, 1rem, center, center);

			.details {
				.title {
					display: none;
				}
				.subtitle {
					@include fontStyle(1.75rem, 600, 1rem);
				}
				width: 19rem;
				.ContactItemsContainer {
					display: none;
				}
			}
			form {
				@include flex(column, 0, space-evenly, start);
				width: 19rem;
				position: relative;
				padding: 0 1rem;
				.InputFields {
					width: 95%;
				}
				.doubleField {
					@include flex(row, 1.4rem, center, start);
					width: 100%;
				}
				.contactBtn {
					width: 100%;
				   }
			}
		}
	}
}
