@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.TermsAndConditionsContainer {
	padding: 3.65rem 5.5rem;
	h3 {
		span {
			@include fontStyle(1rem, 500, "");
			padding-left: 0.4rem;
		}
	}
	li.itemContainer {
		h4 {
			margin-bottom: 0;
			margin-top: 1.5rem;
		}
		p {
			// @include fontStyle(1.1rem, 500, 1.5rem);
			margin-top: 0.3rem;
			border: 1p solid red;
			.email,
			.phone,
			.address {
				font-weight: 700;
			}
		}
		.subListItems {
			padding: 0 0 0.3rem 0.2rem;
			.subListItemsTitle {
				font-weight: 700;
				padding: 0 0.3rem;
			}
			span {
				.descSubListItems {
					padding-left: 1rem;
					p {
						margin: 0.3rem 0;
					}
				}
			}
		}
	}
}

@media (max-width: "768px") {
	.TermsAndConditionsContainer {
		padding: 1rem 2rem;
	}
}
