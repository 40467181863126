@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.supportModal {
	@include flex(column, 0, space-evenly, center);
	height: 28rem;
	width: 35rem;
	border-radius: 1rem;
	background-color: #ffffff;
	h1 {
		margin: 0;
		text-align: left;
		padding-left: 3rem;
		width: 100%;
	}
	.InputFields {
		@include flex(column, 0.5rem, center, start);

		width: 90%;
		label {
			@include fontStyle(1.125rem, 500, 1.361rem);
		}
		input,
		textarea {
			border: none;
			border-radius: 0.4rem;
			width: 94%;
			padding: 1rem;
			background-color: #f6f4f4;
		}
	}
	.doubleField {
		@include flex(row, 1.4rem, center, start);
	}
	.contactBtn {
		@include fontStyle(1rem, 600, 1.21rem);
		background-color: #2a565e;
		color: #ffffff;
		width: 90%;
		padding: 1rem;
		border-radius: 10px;
		border: none;
	}
}

@media (max-width: 768px) {
	form {
		@include flex(column, 0, space-evenly, start);
		width: 17.5rem;
		position: relative;

		h1 {
			padding-left: 0rem;
		}

		.InputFields {
			width: 95%;
		}
		.doubleField {
			@include flex(column, 1rem, space-evenly, start);
			width: 95%;
		}
		.contactBtn {
			width: 100%;
		}
	}
}
