@mixin flex($direction, $gap, $justifyContent, $alignItems) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
	flex-direction: $direction;
	gap: $gap;
}

@mixin fontStyle($fontSize, $fontWeight, $lineHeight) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
}

.FooterContainer {
	@include flex(row, 0, space-between, center);
	background-color: #2a565e;
	height: 26.0625rem;
	padding: 0 10rem;
	.leftSection {
		@include flex(column, 2rem, center, start);
		.logo {
			@include flex(row, 0.5rem, start, center);
		}
		.InputSearchField {
			width: 100%;
			label {
				@include fontStyle(1.125rem, 500, 1.875rem);
				color: #ffffff;
			}
			@include flex(column, 0.5rem, center, start);
			.Inputcontainer {
				@include flex(row, 0, space-between, center);
				border: 2px solid #d1b44d;
				border-radius: 4.375rem;
				width: 100%;
				padding-left: 1rem;
				input {
					@include fontStyle(1.125rem, 500, 1.875rem);
					border: none;
					background-color: transparent;
					padding-right: 1rem;
					outline: none;
					color: #ffffff;
				}
				input::placeholder {
					color: #ffffff;
					opacity: 1;
				}
				.circle {
					@include flex(row, 0, center, center);
					height: 2.875rem;
					width: 2.875rem;
					background-color: transparent;
					cursor: pointer;
					border: 1px solid #d1b44d;
					border-radius: 50%;
					img {
						height: 1.5rem;
						width: 1.5rem;
					}
				}
			}
		}
		p {
			@include fontStyle(1.125rem, 500, 1.875rem);
			color: #ffffff;
		}
	}
	.rightSection {
		@include flex(column, 1rem, start, start);
		height: 15rem;
		flex-wrap: wrap;
		a,
		p {
			@include fontStyle(1.125rem, 500, 1.875rem);
			text-decoration: none;
			color: #ffffff;
			cursor: pointer;
			margin: 0;
		}
		& > a:nth-child(n + 2) {
			margin-right: 3rem;
		}
	}
}

@media (max-width: 1024px) {
	.FooterContainer {
		padding: 0 2.5rem;
		.leftSection {
			width: 40%;
		}
		.rightSection {
			width: 40%;
			& > a:nth-child(n + 2) {
				margin-right: 2rem;
			}
		}
	}
}

@media (max-width: 768px) {
	.FooterContainer {
		@include flex(column, 0, space-between, center);
		height: fit-content;
		position: relative;
		padding: 2rem 2rem 7rem;
		.leftSection {
			@include flex(column, 2rem, center, start);
			width: 100%;

			.InputSearchField {
				width: 100%;
				label {
					@include fontStyle(1rem, 500, 1.875rem);
				}
				.Inputcontainer {
					width: 99%;
					padding-left: 0.5rem;
					input {
						@include fontStyle(1rem, 500, 1.875rem);
					}
				}
			}
			p {
				@include fontStyle(1rem, 500, 1.875rem);
				margin: 0;
                padding-bottom:1.5rem ;
				position: absolute;
				bottom: 0;
			}
		}
		.rightSection {
			@include flex(column, 0.5rem, start, start);
			width: 100%;
			margin-top: 1rem;
			height: fit-content;
			a,p {
				@include fontStyle(1rem, 500, 1.875rem);
				margin: 0;
			}
		}
	}
}
